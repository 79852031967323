<template>
  <!-- 大盒子 -->
  <div class="wrap">支付成功</div>
</template>
<script>
import {  getDomain } from "@/api/cookies";
export default {
  data() {
    return {
      copyright: {},
    };
  },
  created() {
    this.copyright = getDomain();
    // this.$router.replace("/my/mineOrder");
    if (this.copyright.entrantsType == 2) {
      this.$router.replace({ path: "/my/mineOrder" });
      // windows.open("/my/mineOrder")
    } else if (this.copyright.entrantsType == 1) {
      this.$router.replace({ path: "/mysixth/mineOrder" });
    } else if (this.copyright.entrantsType == 3) {
      this.$router.replace({ path: "/seventhPage/my/mineOrder" });
    }
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.wrap {
  background: #fff;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}
</style>
